import React from 'react'
import { viewNames, viewNamesOnBoarding } from '../../common/globalConstants'
import ProjectnameInput from '../components/ProjectnameInput'
import EditOutlineContent from '../../DrawingTool/components/DrawingToolContent'
import { FloorplanContent } from '../../floorplan/FloorplanContent'
import { PeopleContent } from '../../people/PeopleContent'
import FloorsContent from '../../floors/components/FloorsContent'

import startImage from '../assets/images/png/01_Onboarding_start.png'
import drawImage from '../assets/images/png/02_Onboarding_draw.png'
import peopleImage from '../assets/images/png/03_Onboarding_people.png'
import floorsImage from '../assets/images/png/04_Onboarding_floors.png'
import finishImage from '../assets/images/png/05_Onboarding_finish.png'

import { iconTypes } from '../../common/components/icons/IconFactory'
import { viewModes } from '../../floorplan/components/plan/FloorplanPanel'
import TestFittingInterferer from '../components/StepSidebarComponents/TestFittingInterferer'
import Footer from '../components/StepSidebarComponents/Footer'

export const stepsNames = {
  START: 'Start',
  STEP_1: 'Step 1',
  STEP_2: 'Step 2',
  STEP_3: 'Step 3',
  PREVIEW: 'Preview',
  FINISH: 'Finish',
}

export const toolGroups = {
  TOOL_1: 'uploadPlan',
  TOOL_2: 'editOutline',
  TOOL_3: 'addDetails',
  TOOL_4: 'assignAreas',
  TOOL_5: 'drawAxes',
}

export const assistantActions = {
  CREATEFLOORPLAN: 'CREATEFLOORPLAN',
  CREATEPEOPLE: 'CREATEPEOPLE',
  CREATEASSIGNMENTS: 'CREATEASSIGNMENTS',
}

const DEFAULT_NEXT_STEP_BUTTON = {
  text: 'Next Step',
  icon: iconTypes.next,
}

const PROJECTNAME_STEP = {
  viewName: viewNamesOnBoarding.START,
  component: <ProjectnameInput/>,
  image: startImage,
  stepTitle: stepsNames.START,
  title: 'Projectinfo',
  description: {
    descriptions: [
      'Start your project by naming it and giving information about the projectlocation and your industry on the right. To name it, just start typing.',
      'You can also add more project information to help you organize your project.',
      'If you’re finished click on „Next Step“ to continue.'],
  },
  process: {
    percent: 5,
    color: '#ff8042',
  },
  nextStepButton: DEFAULT_NEXT_STEP_BUTTON,
}
const PROJECTNAME_STEP_WITH_FOOTER = Object.assign({
  childComponents: [
    <Footer
      footerLinkText={'Skip Onboarding'}
      footerLink={'./../../' + viewNames.FLOORPLAN}/>,
  ],
}, PROJECTNAME_STEP)


const EDIT_OUTLINE_STEP = {
  viewName: viewNamesOnBoarding.STEP_1,
  component: <EditOutlineContent onboarding/>,
  image: drawImage,
  stepTitle: stepsNames.STEP_1,
  stepDuration: '~ 6 min.',
  editOutlineDescription: {
    upload: {
      title: 'Prepare plan data',
      descriptions: [
        'To prepare your building\'s plan data for test fitting, first upload an image of it.',
        '(CoDesigner supports JPEG-, PNG-, PDF- & DXF-files.)',
      ],
    },
    scale: {
      title: 'Define Scale',
      descriptions: [
        'Determine the scale of your uploaded image by measuring the distance between two points of your choice.',
        'Click anywhere on the image to set the first point. After setting the second one, enter their distance.',
      ],
      shortcuts: [
      ],
    },
    draw: {
      title: 'Draw building Walls',
      descriptions: [
        'First draw the Facade. Facade walls must always be closed',
        "Next draw the inner walls. If an end of an inner wall doesn't touch another wall, the wall gets deleted.",
      ],
      shortcuts: [
      ],
    },
    spaces: {
      title: '' +
        'Define Spacetypes',
      descriptions: [
        'Spaces are areas where people work, hold meetings, and those where people move around.',
        'There are 5 different Types of spaces',
        'To change them, select a type and click by clicking inside.',
      ],
      shortcuts: [
      ],
    },
    details: {
      title: 'Connect spaces with doors',
      descriptions: [
        'Place doors between areas that need to be connected. For example, two openspaces with each other or an openspace with an area where people move.',
      ],
      advancedTitle: 'Add details',
      advancedDescriptions: [
        'At first place doors between areas that need to be connected. For example, two openspaces with each other or an openspace with an area where people move.',
        'Also, place windows on the outer lines of spaces and pillars at, for example, the original position in your plan. Both will have impact on the layout of your floorplan.',
      ],
      shortcuts: [
      ],
    },
    areas: {
      title: 'Define Areatypes',
      descriptions: [
        'Use the corresponding tool and click into the spaces to define whether they are openspaces, meeting rooms, areas where people move around or blocked ones.',
      ],
    },
    axes: {
      title: 'Create distribution axes',
      descriptions: [
        'CoDesigner places objects along distribution axes. Define where they are located within Openspaces with simple clicks, or let CoDesigner create them automatically.',
      ],
      shortcuts: [
      ],
    },
    preview: {
      title: 'NONE',
    },
  },
  uploadDemo: {
    title: 'Lost in space',
    text: 'Hi there, I’m your assistant. Do you want me to draw and prepare a demoplan for you? (Your current draw changes will be lost)',
    notFulfilledText: 'You have not prepared any plan data yet. Create at least one “Space” to continue or ask your assistant to prepare a demo plan for you.',
    buttonText: 'Prepare',
    action: assistantActions.CREATEFLOORPLAN,
  },
  process: {
    percent: 10,
    color: '#ff8042',
  },
  nextStepButton: DEFAULT_NEXT_STEP_BUTTON,
}

const PEOPLE_STEP = {
  viewName: viewNamesOnBoarding.STEP_2,
  component: <PeopleContent/>,
  image: peopleImage,
  stepTitle: stepsNames.STEP_2,
  stepDuration: '~ 2 min.',
  title: 'Create & Define',
  description: {
    descriptions: ['The data in the People tab corresponds to your company\'s data: Number and Names of teams, the required number of employees (headcount), workplaces and much more.'],
    infoTitle: 'Continue your project by trying to:',
    infoItems: [
      {
        icon: iconTypes.addUnit,
        title: 'Add a new Unit',
        description: 'A Unit consists of a Department and a Team',
      },
      {
        icon: iconTypes.plus,
        title: 'Add a new Item',
        description: 'Select a Unit to add a Department or a Department to add a Team',
      },
      {
        icon: iconTypes.editValues,
        title: 'Edit the values',
      },
    ],
  },
  assistant: {
    title: 'Pretty quiet here',
    text: 'Would you like me to create the data for you? You can adjust the values or delete the items at any time.',
    notFulfilledText: 'Add at least one unit to continue, or ask the assistant to help you create the data.',
    buttonText: 'Create',
    action: assistantActions.CREATEPEOPLE,
  },
  process: {
    percent: 60,
    color: '#ff8042',
  },
  nextStepButton: DEFAULT_NEXT_STEP_BUTTON,
}
const FLOORS_STEP = {
  viewName: viewNamesOnBoarding.STEP_3,
  component: <FloorsContent shortVersion onboarding/>,
  image: floorsImage,
  stepTitle: stepsNames.STEP_3,
  stepDuration: '~ 2 min.',
  title: 'Assign teams',
  description: {
    descriptions: ['The capacity of your floor is displayed in CoDesigner like this. If you assign departments or teams to it, they will be represented as brackets. The more space you have planned for your teams in the People tab, the bigger they will be.'],
    infoTitle: 'Try this to go on:',
    infoItems: [
      {
        icon: iconTypes.assignItem,
        title: 'Assign departments or teams to the floor',
        description: 'by dragging them from the right sidebar and dropping them onto the floor.',
      },
      {
        icon: iconTypes.delete,
        title: 'Remove from the floor',
        description: 'by dragging the brackets from the floor and dropping them anywhere on the screen.',
      },
    ],
  },
  assistant: {
    title: 'No one here yet',
    text: 'Do you need any help? I can assign the departments and teams to your floor for you. Let’s see how many can fit.',
    notFulfilledText: 'Assign at least one department or team to continue or ask the assistant to do it for you.',
    buttonText: 'Assign',
    action: assistantActions.CREATEASSIGNMENTS,
  },
  process: {
    percent: 80,
    color: '#ff8042',
  },
  nextStepButton: {
    text: 'Generate Floorplan',
    icon: iconTypes.floorplan,
    action: 'optimiseFloorPlacing',
  },
}

/*
const PREVIEW_STEP = {
  viewName: viewNamesOnBoarding.PREVIEW,
  component: <FloorplanContent viewMode={'team'} onboarding disableInteractions/>,
  image: finishImage,
  stepTitle: stepsNames.PREVIEW,
  title: 'Well done!',
  descriptions: [
    'You have made it! You are ready to work with CoDesigner. Take a first look at your floor plan.',
    'To customize it further, click on Start planning. This finishes the onboarding and takes you to the general user interface of CoDesigner.'],
  infoTitle: 'There you can:',
  infoItems: [
    {
      icon: iconTypes.suitcase,
      title: 'Try different cultural layouts',
    },
    {
      icon: iconTypes.editValues,
      title: 'Edit the values',
    },
    {
      icon: iconTypes.quality,
      title: 'Inspect the quality of your floorplan',
    },
    {
      icon: iconTypes.heatmap,
      title: 'Activate different heatmaps',
    },
    {
      icon: iconTypes.exportFile,
      title: 'Export your floorplan as PDF',
    },
  ],
  processPercent: 100,
  processColor: '#60bf82',
  nextStepButtonTitle: 'Finish',
  nextStepButtonIcon: iconTypes.floorplan,
}
 */

const TESTFIT_STEP = {
  viewName: viewNamesOnBoarding.TESTFIT,
  component: <FloorplanContent viewMode={viewModes.activity} onboarding disableInteractions disableLegend/>,
  image: finishImage,
  stepTitle: stepsNames.FINISH,
  title: 'Well done!',
  testFit: true,
  process: {
    percent: 100,
    color: '#60bf82',
  },
  nextStepButton: {
    text: 'Download as PDF',
    action: 'downloadFloorplanPdf',
  },
  beforeViewAction: 'generateAll',
  childComponents: [<TestFittingInterferer/>],
}

export const assistantSteps = [
  PROJECTNAME_STEP,
  EDIT_OUTLINE_STEP,
  TESTFIT_STEP,
]

export const advancedSteps = [
  PROJECTNAME_STEP_WITH_FOOTER,
  EDIT_OUTLINE_STEP,
  PEOPLE_STEP,
  FLOORS_STEP,
]



