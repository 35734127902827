import React from 'react'
import { Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'

import { updateProject } from '../../common/redux/projects/projects-actions'

import './ProjectnameInput.scss'
import Text from '../../common/components/Text'
import ModalConsumer from '../../common/components/modal/ModalConsumer'
import FurtherInfoDialog from './FurtherInfoDialog'
import Button from '../../common/components/Button'
import { viewNames, viewNamesOnBoarding } from '../../common/globalConstants'
import SelectButton from '../../common/components/SelectButton'
import { updateFrontendLocation } from '../../common/redux/frontendLocation/frontend-location-action'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

function ProjectNameInput() {

  const project = useSelector(state => state.projects.project)
  const industryAttributes = useSelector(state => state.industryAttributes.industryAttributes)
  const countries = useSelector(state => state.countries.countries)
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { projectId } = useParams();


  const isSubmitDisabled = (values) => {
    console.log("is submit disabled")
    return !project ||
      values.name === '' ||
      values.country === '' ||
      values.industry === ''
  }

  const handleSubmit = (values) => {
    console.log("NAME: "+values.name)
    if (values.name !== project.name) {
      updateProject(values.id, values)
    }

    if (values.name !== '') {

      console.log("navigationg to onboarding step 1")
      navigate(`/projects/${projectId}/${viewNames.ONBOARDING}/${viewNamesOnBoarding.STEP_1}`)
      dispatch(updateFrontendLocation('ONBOARDING', 'STEP_1'))
    }
  }

    let industryOptions = [{ label: 'Default', value: '' }]

    industryOptions = industryOptions.concat(industryAttributes.map(attribute => ({
      label: attribute.name,
      value: attribute.name,
    })))

    let countryOptions = [{ label: 'Default', value: '' }]

    countryOptions = countryOptions.concat(countries.map(country => ({
      label: country.name,
      value: country.code,
    })))

    return (
      <React.Fragment>
        <div className={'projectname-input-content'}>
          <div className={'projectname-input-container' + (advancedSettings ? ' advanced-settings' : '')}>
            <Formik initialValues={project} onSubmit={() => {}}>
              {({ values }) => {
                return (
                  <Form className={'projectname-form'}>
                    <Text outerClassName={'title'}>Projectname</Text>
                    <Field className={'projectname-input'}
                           placeholder={'Name your project'}
                           type={'input'}
                           autoFocus
                           autoComplete={'off'}
                           name={'name'}/>
                    <div className={'project-data-selection-area'}>
                      <div className={'project-data-selection'}>
                        <Text outerClassName={'title'}>Country</Text>
                        <Field name="country"
                               options={countryOptions}
                               component={SelectButton}/>
                      </div>

                      <div className={'project-data-selection'}>
                        <Text outerClassName={'title'}>Industry</Text>
                        <Field name="industry"
                               options={industryOptions}
                               component={SelectButton}/>
                      </div>
                    </div>

                    <ModalConsumer>
                      {({ showModal }) => (
                        <div className={'project-further-information'} onClick={() => showModal(FurtherInfoDialog)}>
                          <Text>Add further information</Text>
                        </div>
                      )}
                    </ModalConsumer>

                    <Field component={Button}
                           id = {advancedSettings ? 'CoDesignerPro' : 'Testfitting'}
                           type={'submit'}
                           className={'projectname-submit-button'}
                           variant={'orange-filled'}
                           disabled={isSubmitDisabled(values)}
                           onClick={() => handleSubmit(values)}>Submit</Field>
                  </Form>
                )
              }
              }
            </Formik>
          </div>
        </div>
      </React.Fragment>
    )

}

export default ProjectNameInput