import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Text, { textTypes } from '../../common/components/Text'
import Button from '../../common/components/Button'
import { AssistantDialog } from './AssistantDialog'
import { viewNames } from '../../common/globalConstants'
import ModalConsumer from '../../common/components/modal/ModalConsumer'
import { autoGenerateAssigmentInFloor, fetchFloorPlanPdf, fetchPlacedOrganisationalUnits, generateAll, optimiseFloorPlacing } from '../../floorplan/actions/actions'
import { getCurrentVariationId } from '../../common/utils'
import { createRandomOrganisationalUnits } from '../../common/redux/organisationunits/organisational-units-actions'
import TemplateDialog from '../../common/components/TemplateDialog'
import { loadDemoFloor } from '../../buildings/actions/actions'
import { iconTypes } from '../../common/components/icons/IconFactory'
import AssistantButton from './StepSidebarComponents/AssistantButton'
import Description from './StepSidebarComponents/Description'
import TestFit from './StepSidebarComponents/TestFit'
import EditOutlineDescription from './StepSidebarComponents/EditOutlineDescription'
import OpenDemoButton from './StepSidebarComponents/OpenDemoButton'
import './StepSidebar.scss'
import { updateFrontendLocation } from '../../common/redux/frontendLocation/frontend-location-action'
import WrongAxesDialog from './WrongAxesDialog'
import { getCurrentGeometryObjects } from '../../DrawingTool/reducer/drawingToolReducer'
import { updateGeometries } from '../../DrawingTool/actions/Backend/drawingToolActions'
import { representationTypes } from '../../DrawingTool/three/config/RepresentationTypes'
import { advancedSteps, assistantActions, assistantSteps, stepsNames } from '../constants/onboarding-constants'
import Scrollbar from 'react-scrollbars-custom'
import { useParams } from 'react-router-dom'

function StepSidebar({step})  {

  const [waitForAxes, setWaitForAxes] = useState(false);
  const [axesDialog, setAxesDialog] = useState(null);
  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams()
  const { placedDepartments, project, units, geometries } = useSelector(state => ({
    placedDepartments: state.placedOrganisationalUnits.placedDepartments,

    project: state.projects.project,
    units: state.organisationalUnits.units,
    geometries: getCurrentGeometryObjects(state),
    // Weitere Zustände nach Bedarf hinzufügen
  }));

  const variationId = getCurrentVariationId(window.location.search)

  const [steps, setSteps] = useState(advancedSettings ? advancedSteps : assistantSteps)

  useEffect(() => {
    setSteps(advancedSettings ? advancedSteps : assistantSteps)
  }, [advancedSettings])

  const activeStep = steps.find(s => s.viewName === step.viewName)
  const currentIndex = steps.indexOf(activeStep)
  const previousView = currentIndex - 1 >= 0 ? steps[currentIndex - 1].viewName : null
  const nextView = currentIndex + 1 < steps.length ? steps[currentIndex + 1].viewName : null
  const beforeNextViewAction = currentIndex + 1 < steps.length ? steps[currentIndex + 1].beforeViewAction : null


  const checkPlacedUnitsAllZero = () => {

    return 0 === placedDepartments.map(dep => dep.area)
      .reduce((a, b) => a + b)
  }

  const closeAxesDialog = () => {
    setAxesDialog(null);
  }

  const handleNextStepClick = (nextStepButton) => {
    handleChangeStepView(nextView, [nextStepButton.action, beforeNextViewAction])
  }

  const handleChangeStepView = (changedStepView, actions = [], skipAxesCheck = false) => {
    //setStepNull()

    console.log("handleChangeStepView: "+nextView);

    const elem = document.getElementById("onbo");
    //elem.innerHTML="<div></div>"
    console.log("elem.innerHTML")
    console.log(elem.innerHTML)
    const pos=elem.innerHTML.indexOf("<div></div>")
    console.log(pos)
    const length=elem.innerHTML.length
    if (pos >=0){
      elem.innerHTML=elem.innerHTML.slice(-10)
      console.log(elem.innerHTML)
      console.log("innerhtml: "+pos);
    }
    /*
    if(elem.innerHTML.startsWith("<div></div>")){
      elem.innerHTML=elem.innerHTML.slice(11);
      console.log("slice finished")
    }*/
    console.log(elem.innerHTML)
    console.log(elem)


    //elem.innerHTML="<div>b</div>"
    console.log("backtostartA")

    let skipChange = false

    if(nextView === "step_2"){
      updateGeometries()

      //console.log("S2")
    }
    actions.forEach(action => {


      if (action) {
        switch (action) {
          case 'optimiseFloorPlacing':
            optimiseFloorPlacing()
            break
          case 'generateAll':
            dispatch(generateAll(0))
            if (!skipAxesCheck) {
              skipChange = true
              setWaitForAxes(true)

            }
            break
          case 'downloadFloorplanPdf':
            dispatch(fetchFloorPlanPdf())
            return
          default:
            break
        }
      }
    })

    console.log("SkipChange: " + skipChange)

    if (skipChange)
      return

    console.log ("going to next view: " + changedStepView)
    goToNextView(changedStepView)
  }

  const goToNextView = (changedStepView) => {
    console.log("ChangedStepView: " + changedStepView)
    const basePath = `/projects/${projectId}`;
    const nextViewPath = changedStepView ? `${basePath}/onboarding/${changedStepView}` : `${basePath}/${viewNames.FLOORPLAN}`;
    const nextView = `${nextViewPath}?variation=${variationId}`;
    dispatch(updateFrontendLocation(changedStepView ? 'ONBOARDING' : 'FLOORPLAN', changedStepView ? changedStepView.toUpperCase() : ''))
      .then(() => {
        console.log("NextView:")
        console.log(nextView)
        navigate(nextView)
      })
  };

  const handleBackStepClick = (stepTitle) => {
    console.log("stepTitle")
    console.log(stepTitle)
    switch (stepTitle) {
      case stepsNames.START:
        handleBackToStart();
        break;
      default:
        handleChangeStepView(previousView); // Angenommen, `previousView` ist im Zustand der Komponente oder kommt von props
        break;
    }
  };

  const handleAssistantForNextStep = (showModal, assistant) => {
    const { title, notFulfilledText, buttonText, action } = assistant

    const assistantAction = getAssistantAction(action)
    console.log("handleaction")
    console.log(action)

    if (action) {
      showModal(TemplateDialog,
        {
          title: title,
          text: notFulfilledText,
          rightButtonText: buttonText,
          rightButtonSecondIcon: 'assistant',
          rightButtonVariant: 'orange-filled',
          onRightButtonClicked: assistantAction,
        },
      )
    }
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getAssistantAction = (assistantAction) => {
    let action = null


    if (!advancedSettings) {
      action = () => {
        (async () => {
          await dispatch(loadDemoFloor());
          await delay(500)
          await dispatch(createRandomOrganisationalUnits());
          await delay(500)
          await dispatch(autoGenerateAssigmentInFloor());
          await delay(500)
          await dispatch(optimiseFloorPlacing())
          //await dispatch(generateAll(0))


        })();
      };
      return action;
    }

    switch (assistantAction) {
      case assistantActions.CREATEFLOORPLAN:
        console.log("demo floor")
        action = () => dispatch(loadDemoFloor())
        break
      case assistantActions.CREATEPEOPLE:
        console.log("create people")
        action = () => dispatch(createRandomOrganisationalUnits())
        break
      case assistantActions.CREATEASSIGNMENTS:
        console.log("create assignments")
        action = () => dispatch(autoGenerateAssigmentInFloor())
        break
      default:
        return
    }

    return action
  }

  const isNextStepAllowed = (stepTitle) => {
    //console.log("test next STEP Allowed: "+stepTitle)
    switch (stepTitle) {
      case stepsNames.START:
        return project && project.name !== ''
      case stepsNames.STEP_1:

        if(geometries.geometries){
          return geometries.geometries.filter(geometry => geometry.representationType === representationTypes.outline).length !== 0
        }else{
          //console.log("not allowed")
          return false
        }

      case stepsNames.STEP_2:
        return units.find(unit => unit.type === 'TEAM')
      case stepsNames.STEP_3:
        return placedDepartments.length > 0
      default:
        return true
    }
  }

  const handleOpenAssistant = (showModal, assistant) => {
    const { text, buttonText, action } = assistant

    const assistantAction = getAssistantAction(action)

    if (action) {
      showModal(AssistantDialog,
        {
          text: text,
          assistantButtonText: buttonText,
          onCreate: assistantAction,
        },
      )
    }
  }


  const handleBackToStart = () => {
    navigate('/'); // Gehe zurück zur Startseite oder einem anderen definierten Pfad
  };

  useEffect(() => {
    console.log("WAIT FOR AXES UPDATED")

    // Die Logik, die ausgeführt werden soll, wenn sich `placedDepartments` ändert
    if (waitForAxes === true && checkPlacedUnitsAllZero()) {
      // Logik, um den Dialog zu öffnen oder eine andere Aktion basierend auf der neuen Zustand zu starten
      setAxesDialog(
        <WrongAxesDialog
          onCancel={() => closeAxesDialog()}
          onConfirm={() => closeAxesDialog()}
          title={'Something went wrong.'}
          text={'Unfortunately, we were not able to place the automatic axes correctly. Please place them manually'}
        />
      );
      console.log("CHECK PLACED UNITS PASSED")
      setWaitForAxes(false);
      //setAxesProcessed(true)

    } else {
      // Möglicherweise weitere Aktionen, wenn die Bedingungen nicht zutreffen
      if (placedDepartments.length > 0 && nextView === 'testfit') {
        goToNextView(nextView)
      }

    }
  }, [placedDepartments]); // Abhängigkeiten von `placedDepartments` und `waitForAxes`



    return (
      <ModalConsumer>
        {({ showModal }) => (
          <div className={'step-sidebar'}>
            <img className={'step-sidebar-image'} src={step.image} alt={'something'}/>
            <div className={'step-sidebar-description' + (step.assistant || step.uploadDemo ? '' : ' expand')}>
              <div className={'step-sidebar-step'}>
                <Text component={textTypes.runningTitle}>{step.stepTitle}</Text>
              </div>
              <Scrollbar className={'step-sidebar-scrollbar'}>
                <div className={'step-sidebar-scrollbar-container'} id={"onbo"}>
                  {step.title ? <Text outerClassName={'step-sidebar-title'} component={textTypes.title}>{step.title}</Text> : null}
                  {step.editOutlineDescription ? <EditOutlineDescription description={step.editOutlineDescription}/> : null}
                  {step.description ? <Description description={advancedSettings && step.advancedDescription ? step.advancedDescription : step.description}/> : null}
                  {step.testFit ? <TestFit/> : null}
                </div>
              </Scrollbar>
            </div>


            {step.assistant ? <AssistantButton onClick={() => handleOpenAssistant(showModal, step.assistant)}/> : null}
            {step.uploadDemo ? <OpenDemoButton onClick={() => handleOpenAssistant(showModal, step.uploadDemo)}/> : null}
            {axesDialog ? axesDialog : null}

            <div className={'step-sidebar-process-bar'}>
              {step.process ? <div style={{ height: '100%', width: step.process.percent + '%', backgroundColor: step.process.color }}/> : null}
            </div>
            <div className={'step-sidebar-next-step'}>
              <Button icon={iconTypes.back} variant={'inverted'}
                      onClick={() => handleBackStepClick(step.stepTitle)}>Back</Button> : <div/>
              <Button className={'step-sidebar-next-step-button' + (nextView ? '' : ' finish')}
                      variant={'filled'}
                      disabled={!isNextStepAllowed(step.stepTitle)}
                      onDisabledClick={() => step.assistant ? handleAssistantForNextStep(showModal, step.assistant) : {}}
                      secondIcon={step.nextStepButton.icon}
                      onClick={() => handleNextStepClick(step.nextStepButton)}>{step.nextStepButton.text}</Button>
            </div>

          </div>)}
      </ModalConsumer>
    );
}


export default StepSidebar