import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useParams } from 'react-router-dom'
import { Routes, useLocation, useNavigate } from 'react-router'
import { viewNames, viewNamesOnBoarding } from '../common/globalConstants'
import Step from './components/Step'

import './OnboardingApp.scss'
import { fetchProjectData } from '../floorplan/actions/actions'

function OnboardingApp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();

  const dataLoaded = useSelector(state => state.dataLoaded.dataLoaded);

  useEffect(() => {
    if (!dataLoaded) {
     dispatch(fetchProjectData(projectId));
    }
  }, [dispatch, projectId, dataLoaded, navigate]);

  useEffect(() => {
    const currentPath = location.pathname;
    const onboardingPath = `/projects/${projectId}/${viewNames.ONBOARDING}`;
    if (currentPath === onboardingPath) {
      navigate(`${onboardingPath}/${viewNamesOnBoarding.START}`);
    }
  }, []);



  return (
    <div id={'onboarding-container'}>
      <Routes>
        <Route path={`/${viewNamesOnBoarding.START}`}
               element={<Step step={viewNamesOnBoarding.START}/>} />
        <Route path={`/${viewNamesOnBoarding.STEP_1}`}
               element={<Step step={viewNamesOnBoarding.STEP_1}/>} />
        <Route path={`/${viewNamesOnBoarding.STEP_2}`}
               element={<Step step={viewNamesOnBoarding.STEP_2}/>} />
        <Route path={`/${viewNamesOnBoarding.STEP_3}`}
               element={<Step step={viewNamesOnBoarding.STEP_3}/>} />
        <Route path={`/${viewNamesOnBoarding.PREVIEW}`}
               element={<Step step={viewNamesOnBoarding.PREVIEW}/>} />
        <Route path={`/${viewNamesOnBoarding.TESTFIT}`}
               element={<Step step={viewNamesOnBoarding.TESTFIT}/>} />
      </Routes>

    </div>
  );
}



export default OnboardingApp