import { Mesh } from 'three'

import PolygonGeometry from './PolygonGeometry'

import { geometryTypes } from '../config/GeometryTypes'
import { materialTypes } from '../common/MaterialTypes'
import { getMaterial } from '../common/Materials'
import { createFaceBufferGeometryRingOffset } from '../helper/Offsetting'
import { getPropertiesByRepresentationType } from '../factories/PropertiesFactory'

import {
  addToOffset,
  getOffset,
  getOffsets,
  setOffset,
  shiftOffsetToLeft,
  shiftOffsetToRight,
} from './utils/OffsetGeometryUtils'
import { addVertexToEdge, moveDelta, updateVertex } from './utils/GeometryBasicFunctionsUtils'
import { getBoundingBox } from '../common/BoundingBox'

export class OffsetPolygonGeometry extends PolygonGeometry {

  constructor (parent, representationType, properties) {
    super(parent, representationType)

    if (properties) {

      console.log(properties)
      this.offsetLeft = properties.offsetLeft
      this.offsetRight = properties.offsetRight
      this.properties = properties
    } else {
      const properties = getPropertiesByRepresentationType(representationType)
      console.log(properties)
      this.offsetLeft = properties.offsetLeft
      this.offsetRight = properties.offsetRight
      this.properties = properties
    }

    this.addToOffset = (step) => {
      addToOffset(this, step)
      this.updateOffsetFace()
    }
    this.setOffset = (offset) => setOffset(this, offset)
    this.getOffset = () => {
      return getOffset(this)
    }
    this.getOffsets = () => {
      return getOffsets(this)
    }

    this.shiftOffsetToLeft = () => {
      shiftOffsetToLeft(this)
      this.updateOffsetFace()
    }
    this.shiftOffsetToRight = () => {
      shiftOffsetToRight(this)
      this.updateOffsetFace()
    }

    this.updateVertex = (position, vertex = null) => {
      updateVertex(position, this, vertex)

      this.updateOffsetFace()
    }
    this.addVertexToEdge = (edge, position) => {
      addVertexToEdge(edge, position, this)

      this.updateOffsetFace()
    }
    this.moveDelta = (movement) => {
      moveDelta(movement, this)

      if (this.offsetFace) {
        this.offsetFace.position.x += movement.x
        this.offsetFace.position.y += movement.y

        this.offsetFace.outerVertices.forEach(vertex => {
          vertex.x += movement.x
          vertex.y += movement.y
        })
        this.offsetFace.innerVertices.forEach(vertex => {
          vertex.x += movement.x
          vertex.y += movement.y
        })
      }

      this.updateGeometryCallback()
    }
  }

  close () {
    super.close()

    this.vertexGroup.setActive(false)
    this.edgeGroup.setActive(false)
    this.createOffsetFace()
  }

  getVerticesPositions () {
    const verticesPositions = super.getVerticesPositions()

    return this.offsetFace ? verticesPositions.concat(this.offsetFace.outerVertices)
      .concat(this.offsetFace.innerVertices) : verticesPositions
  }

  getNearestEdgeToPosition (position) {
    return this.edgeGroup.getNearestEdgeToPosition(position)
  }

  createOffsetFace () {
    const { geometry, innerVertices, outerVertices } = createFaceBufferGeometryRingOffset(this.vertexGroup.getPositions(), this.offsetLeft, this.offsetRight)

    this.offsetFace = new Mesh(geometry, getMaterial(materialTypes.black))
    this.offsetFace.geometryType = geometryTypes.offsetFace
    this.offsetFace.innerVertices = innerVertices
    this.offsetFace.outerVertices = outerVertices

    this.add(this.offsetFace)
  }

  updateOffsetFace () {
    const { offsetFace, vertexGroup, offsetLeft, offsetRight } = this

    if (!offsetFace)
      return

    offsetFace.position.set(0, 0, 0)
    offsetFace.geometry.dispose()
    const { geometry, innerVertices, outerVertices } = createFaceBufferGeometryRingOffset(vertexGroup.getPositions(), offsetLeft, offsetRight)
    offsetFace.geometry = geometry
    offsetFace.innerVertices = innerVertices
    offsetFace.outerVertices = outerVertices

    this.edgeGroup.updateAllEdgesCallbacks()
  }

  getBoundingBox () {
    return getBoundingBox(this.offsetFace.outerVertices)
  }

  dispose () {
    super.dispose()

    if (this.offsetFace && this.offsetFace.geometry) {
      this.offsetFace.geometry.dispose()
    }
  }

  fromJSON (json) {
    super.fromJSON(json)

    this.createOffsetFace()
  }

  toJSON () {
    const json = super.toJSON()

    json.properties = {
      offsetLeft: this.offsetLeft,
      offsetRight: this.offsetRight,
    }

    return json
  }
}

