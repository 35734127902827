import React, { Component } from 'react'
import { connect } from 'react-redux'

import Text, { textTypes } from '../../../common/components/Text'
import { getSelectedBuilding, getSelectedFloor } from '../../../floorplan/reducers/buildings'

import './EditOutlineDescription.scss'

class EditOutlineDescription extends Component {

  render () {
    console.log("EditOutlineDescription")
    //console.log(this.props)
    //const description = null

    const description = this.props.description ? this.props.description[this.props.currentDrawingTab] : null

    //console.log("description")
    //console.log(description)

    if (!description){
      return null
    }


    let { title, advancedTitle, descriptions, advancedDescriptions, shortcuts } = description
    console.log("shortcuts")
    console.log(shortcuts)
    descriptions = this.props.advancedSettings && advancedDescriptions ? advancedDescriptions : descriptions
    title = this.props.advancedSettings && advancedTitle ? advancedTitle : title

    return (
      <React.Fragment>
        <Text outerClassName={'step-sidebar-title'} component={textTypes.title}>{title}</Text>
        <div className={'step-sidebar-container'}>
          {descriptions ?
            <div className={'step-sidebar-descriptions'}>
              {descriptions.map((description, index) => {
                return description ?
                  <Text outerClassName={'step-sidebar-description-text'} component={textTypes.paragraph} multiline key={index}>
                    {description}
                  </Text> :
                  <div className={'step-sidebar-description-separator'} key={index}/>
              })}
            </div> : null}

          {
            shortcuts ?
              <div className={'step-sidebar-shortcuts'}>
                <Text component={textTypes.paragraph} outerClassName={'step-sidebar-shortcuts-title'}>Shortcuts</Text>
                {
                  shortcuts.map((shortcut, index) => {
                    return <Text component={textTypes.paragraph} outerClassName={'step-sidebar-shortcut'} key={index}>{shortcut}</Text>
                  })
                }
              </div> : null}
        </div>
      </React.Fragment>)
  }
}

let mapStateToProps = state => {
  //console.log("state")
  //console.log(state)
  return {
    currentBuilding: getSelectedBuilding(state),
    currentFloor: getSelectedFloor(state),
    advancedSettings: state.settings.appSettings.advancedSettings,
  }
}

export default connect(mapStateToProps)(EditOutlineDescription)