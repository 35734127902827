import {Group} from 'three'
import {Edge} from './EdgeFactory'
import {geometryTypes} from '../config/GeometryTypes'
import {insertChildIntoParent} from '../helper/GroupHelper'

export class EdgeGroup extends Group {

    constructor(parent, vertexGroup) {
        super()
        //console.log("new edgegroup")
        this.name = 'edgeGroup'
        this.currentScale = 1
        this.geometryType = geometryTypes.edgeGroup

        this.position.z = 0.02

        this.vertexGroup = vertexGroup

        parent.add(this)
    }

    addEdge(startVertex, endVertex) {
        //console.log("add Edge start: "+startVertex.position.x+":"+startVertex.position.y)
        //console.log("add Edge end: "+endVertex.position.x+":"+endVertex.position.y)
        //console.log("add Edge in Group")
        const newEdge = new Edge(startVertex, endVertex, this.currentScale, this.getRepresentationType())
        newEdge.setActive(true)
        this.add(newEdge)
    }

    addEdgeAtIndex(startVertex, endVertex, index) {

        const newEdge = new Edge(startVertex, endVertex, this.currentScale, this.getRepresentationType())
        newEdge.setActive(true)

        insertChildIntoParent(this, newEdge, index)
    }

    addVertexToEdge(edge, position) {
        const neighborVertices = edge.getVertices()

        if (neighborVertices.length !== 2)
            return

        const newVertex = this.vertexGroup.addVertex(position)
        newVertex.setActive(true)

        const previousEdge = new Edge(neighborVertices[0], newVertex, this.currentScale, this.getRepresentationType())
        previousEdge.setActive(true)
        this.add(previousEdge)
        const nextEdge = new Edge(newVertex, neighborVertices[1], this.currentScale, this.getRepresentationType())
        nextEdge.setActive(true)
        this.add(nextEdge)

        this.removeEdge(edge)
        return newVertex
    }

    removeEdge(edge) {
        edge.getVertices().forEach(vertex => {
            vertex.removeEdge(edge)
        })

        edge.dispose()

        this.remove(edge)
    }

    removeEdges(edges) {
        edges.forEach(edge => this.removeEdge(edge))
    }

    updateEdge(edge) {
        const vertices = edge.getVertices()

        if (vertices.length !== 2)
            return

        edge.update(vertices[0].position, vertices[1].position)
    }

    getRepresentationType() {
        if (this.parent && this.parent.representationType)
            return this.parent.representationType
    }

    updateAllEdges() {
        this.children.forEach(edge => this.updateEdge(edge))
    }

    updateAllEdgesCallbacks() {
        this.children.forEach(edge => edge.updateEdgeCallback())
    }

    updateScale(scale) {
        this.children.forEach(edge => edge.updateScale(scale))

        this.currentScale = scale
    }

    setActive(active) {
        this.children.forEach(edge => edge.setActive(active))
    }

    getNearestEdgeToPosition(position) {
        console.log("nearest edge group")
        let currentDistance = Number.MAX_VALUE
        let nearestEdge = null

        this.children.forEach(edge => {
            const distance = edge.getDistanceToEdge(position)
            if (distance < currentDistance) {
                currentDistance = distance
                nearestEdge = edge
            }
        })

        return nearestEdge
    }

    findEdgeByUuid(uuid) {
        return this.children.find(edge => edge.uuid === uuid)
    }

    findVerticesByUuids(verticesUuids) {
        return this.vertexGroup.findVerticesByUuids(verticesUuids)
    }

    getEdges() {
        return this.children.flat()
    }

    getEdgeByIndex(index) {
        return this.children.length ? this.children[(index + this.children.length) % this.children.length] : null
    }

    dispose() {
        //console.log("dispose edges"+this.children.length)
        this.children.forEach(edge => edge.dispose())
        //console.log("finished"+this.children.length)
    }

    fromJSON(edgesJSON) {
        edgesJSON.forEach(json => {
            const startVertex = this.vertexGroup.findVertexByUuid(json.startVertex)
            const endVertex = this.vertexGroup.findVertexByUuid(json.endVertex)

            const edge = new Edge(startVertex, endVertex, this.currentScale, this.getRepresentationType(), json.uuid)

            this.add(edge)
        })
    }

    toJSON() {
        return this.children.map(edge => edge.toJSON())
    }
}