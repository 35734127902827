import { GeometryController } from './GeometryController'
import { representationTypes } from '../config/RepresentationTypes'
import { SpaceGeometry } from '../geometries/SpaceGeometry'
import { isVerticesContainsVertex } from '../helper/GeometryHelper'
import { convertListVector2ToVector3, includesVector } from '../common/VectorConverter'
import { Graph, graphRepresentationTypes } from '../graph/Graph'
import { checkNestedForOutlines } from '../common/AreaDetection'
import { removeAllChildren } from '../../../common/three/drawing/drawConstants'

export class SpaceController extends GeometryController {
  oldSpaces
  oldMarkerPositions
  oldTypes
  oldByHand
  oldAreas
  collectMarkers(spaces){
    const markerList = []
    const typeList = []
    const byHandList = []
    const areaList =[]
    console.log("Children: "+spaces)
    console.log("n Children: "+spaces.length)
    for(let i =0; i<spaces.length; i++){
      let markerPos=spaces[i].getMarkerPos()
      let type=spaces[i].getSpaceType()
      let byHand=spaces[i].getByHand()
      let area=spaces[i].area
      if(markerPos){
        markerList.push(markerPos)
        typeList.push(type)
        byHandList.push(byHand)
        areaList.push(area)
        console.log("m "+type+"area:"+area+" bh: "+byHand+" xy: "+markerPos.x+":"+markerPos.y)
      }

    }
    //this.oldMarkerPositions.push(markerList)
    this.oldMarkerPositions=markerList
    this.oldByHand=byHandList
    this.oldTypes=typeList
    this.oldAreas=areaList
  }
  createSpaces (geometries) {
    console.log("create spaces - new")
    this.oldSpaces =[]

    for(let i=0; i<this.group.children.length; i++){
      this.oldSpaces.push(this.group.children[i])
    }
    //const oldSpaces=[]
    console.log("create Spaces - oldSpaces: "+this.oldSpaces.length)
    this.collectMarkers(this.oldSpaces)
    //this.oldMarkers =this.collectMarkers()

    //console.log("OML:"+this.oldMarkers.length)
    removeAllChildren(this.group)
    const outlines = geometries.filter(geometry => geometry.representationType === representationTypes.outline)
    const walls = geometries.filter(geometry => geometry.representationType === representationTypes.wall)

    const innerVerticesPerOutline = outlines
      .map(outline => outline.offsetFace)
      .filter(offsetFace => !!offsetFace)
      .map(offsetFace => offsetFace.innerVertices)
      .filter(innerVertices => innerVertices && innerVertices.length)

    checkNestedForOutlines(outlines, innerVerticesPerOutline)

    let verticesPerWallGeometry = walls.filter(wall => wall.offsetFace && wall.offsetFace.vertices)
      .map(wall => {
        return {
          geometryUuid: wall.uuid,
          positions: wall.offsetFace.vertices,
          representationType: graphRepresentationTypes.wall,
        }
      })


      //console.log("vertices per wall:"+verticesPerWallGeometry)

    outlines.forEach(outline => {
      let parentCounter = 0

      let currentGeometry = outline.getParentGeometry()
      while (currentGeometry != null) {
        currentGeometry = currentGeometry.getParentGeometry()
        parentCounter++
      }

      if ((parentCounter % 2) === 0) {
        const innerOutline = outline.offsetFace.innerVertices
        const outerOutline = outline.offsetFace.outerVertices
        let innerHoles = outline.nestedGeometries
          .filter(geometry => geometry.representationType === representationTypes.outline)
          .map(geometry => geometry.offsetFace.outerVertices)
        let outerHoles = outline.nestedGeometries
          .filter(geometry => geometry.representationType === representationTypes.outline)
          .map(geometry => geometry.offsetFace.outerVertices)

        let graphGeometries = verticesPerWallGeometry.filter(geometry => this.isGeometryInside(geometry, outerOutline, innerHoles))

        graphGeometries.push({
          geometryUuid: outline.uuid,
          positions: outline.offsetFace.innerVertices,
          representationType: graphRepresentationTypes.outerOutline,
        })
        graphGeometries = graphGeometries.concat(outline.nestedGeometries
          .filter(outline => outline.offsetFace && outline.offsetFace.innerVertices)
          .map(outline => {
            return {
              geometryUuid: outline.uuid,
              positions: outline.offsetFace.outerVertices,
              representationType: graphRepresentationTypes.innerOutline,
            }
          }))

        this.createSpaceGraph(graphGeometries, innerOutline, outerHoles)
      }
    })
  }

  isGeometryInside (geometry, outline, holes) {
    let insideOutline = geometry.positions.filter(position => isVerticesContainsVertex(outline, position))
    holes.forEach(hole => {
      insideOutline = insideOutline.filter(position => !isVerticesContainsVertex(hole, position))
    })

    return insideOutline.length > 0
  }

  createSpaceGraph (graphGeometries, outline, holes) {
    console.log("createSpaceGraph!")

    const graph = new Graph(graphGeometries, outline, holes)

    const spaces = graph.getFaces()

    console.log("n spaces:"+spaces.length)
    spaces.forEach(space => {
      const outline = convertListVector2ToVector3(space.getOutline())
      console.log("space outline: "+outline.length)
      const holes = space.getHoles()
        .map(hole => convertListVector2ToVector3(hole))
      console.log("space holes: "+holes.length)
      const spaceGeometry = new SpaceGeometry(this.group, this.representationType,
        { vertices: outline, holes: holes })

      console.log("children:"+this.group.children.length)
      console.log("area:"+spaceGeometry.area)
      this.group.add(spaceGeometry)
    })

    //const newSpaces =[]
    const assignedUuids = []

    this.group.children.forEach(space => {

      const vertices = space.getVertices()
      const positions = []
      vertices.forEach(vertex => {
        positions.push(vertex.position)
      })

      this.oldSpaces.forEach(oldSpace => {
        if (assignedUuids.includes(oldSpace.uuid)) {
          return
        }

        const oldVertices = oldSpace.getVertices()
          .map(vertex => vertex.position)

        let sameVertices = vertices.filter(vertex => includesVector(oldVertices, vertex.position)).length
        console.log("OLDVERTS"+oldVertices.length)
        //if (sameVertices -3 <= oldVertices.length && oldVertices.length <= sameVertices + 3) {
        if (sameVertices===oldVertices.length){
          console.log("SAME: "+sameVertices)
          console.log("type:"+oldSpace.getSpaceType())
          //newSpaces.push(space)
          space.face.config=oldSpace.face.config
          space.setUseSpaceType(oldSpace.getUseSpaceType());

          console.log(oldSpace.getUseSpaceType()+"    "+space.getUseSpaceType())
          space.uuid = oldSpace.uuid

          if(oldSpace.getMarkerPos()!=null) {
            space.setSpaceType(oldSpace.getMarkerPos(), oldSpace.getSpaceType())
          }

          console.log("type:"+space.getSpaceType())

          if(oldSpace.getByHand()===true){
            space.setUnknown(oldSpace.getUnkown)
            space.setByHand(true);
            space.setAxes(oldSpace.getAxes())
          }

          space.setMarkerPos(oldSpace.getMarkerPos())
          space.updateSpaceTextures()
          assignedUuids.push(oldSpace.uuid)
        }else{
        }
      })
    })
    this.group.children.forEach(space => {
      space.updateSpaceTextures()
      if(space.getSpaceType()==="zone"){
        if (space.getByHand()===false){
          space.setUnknown(false)

        }
      }
    })
  }

}