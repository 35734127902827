import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import StepSidebar from './StepSidebar'

import { advancedSteps, assistantSteps } from '../constants/onboarding-constants'

import './Step.scss'

//import { svgToolsListNew } from '../constants/SvgTools'

function Step({step}) {



  const advancedSettings = useSelector(state => state.settings.appSettings.advancedSettings);

  const [steps, setSteps] = useState(advancedSettings ? advancedSteps : assistantSteps)

  const stepItem = steps.find(s => s.viewName === step);
  useEffect(() => {
    setSteps(advancedSettings ? advancedSteps : assistantSteps)

  }, [advancedSettings])

  if (!stepItem)
    {
      console.log("zero step")
      return null;
    }
  console.log(steps)

  const currentIndex = steps.indexOf(stepItem);
  const previousView = currentIndex - 1 >= 0 ? steps[currentIndex - 1].viewName : null;
  const nextView = currentIndex + 1 < steps.length ? steps[currentIndex + 1].viewName : null;
  const beforeNextViewAction = currentIndex + 1 < steps.length ? steps[currentIndex + 1].beforeViewAction : null;


  return (
    <div className={'step-container'}>
      <StepSidebar step={stepItem} nextView={nextView} previousView={previousView} beforeNextViewAction={beforeNextViewAction}/>
      <div className={'step-content'}>
        {stepItem.component}
      </div>
      {stepItem.childComponents ? stepItem.childComponents.map((component, index) => React.cloneElement(component, { key: index })) : null}
    </div>
  );
}

export default Step