import { GeometryController } from './GeometryController'
import { disableMagicGuideLine, getSnappingPosition,getClosestDirection } from '../helper/MagicGuideLine'
import { setPreviewVertex } from '../helper/PreviewVertex'
import { setPreviewOtherVertex } from '../helper/PreviewOtherVertex'
import { geometryTypes } from '../config/GeometryTypes'
import { Vector2 } from 'three'
import {
  getCrossPoint,
  getAddition,
  getPerp,
  getSubtraction,
  getUnitVector,
  vector3ToVector2,
  vector2ToVector3, getDistanceToEdge,
} from '../helper/GeometryHelper'
import {representationTypes} from '../config/RepresentationTypes'
import floorIcon from '../../../common/components/icons/FloorIcon'


export class PolyEdgeController extends GeometryController {

  constructor (floorPlanerController, representationType, group) {
    super(floorPlanerController, representationType, group)
    this.vertexMode=true
  }
  setActive (active) {
    if (active) {
      const position = this.floorPlanerController.getCurrentPosition()
      setPreviewVertex(position, this.representationType)
    } else {
      setPreviewVertex()
    }
  }

  toggleVertexMode(){
    this.vertexMode=!this.vertexMode
    //this.vertexMode=true  //vorübergehend
    //console.log("vertexMode: "+this.vertexMode)
    const position = this.floorPlanerController.getCurrentPosition()
    if(this.vertexMode===false){
      setPreviewVertex()
    }else{
      setPreviewVertex(position, this.representationType)
    }
  }
  onLeftMouseDownNew (event, geometry, currentGeometries) {

    const currentPosition = this.getCurrentPosition(event, geometry.draggedVertex ? geometry.draggedVertex : null, true,geometry)


    geometry.addVertex(currentPosition)
    geometry.addVertex(currentPosition)
    //console.log("geometryyy")
    //console.log(geometry.getVertices().length)
    //console.log(geometry)

    geometry.setAction(geometry.actions.addVertex)

    currentGeometries.push(geometry)

    return true
  }

  onMouseMove (event, geometry, currentGeometries) {
    //console.log("onMousemove")
    const result = this.onMouseMovePreviewVertex(event, geometry, currentGeometries)
    //console.log("result: "+result)
    if (result !== undefined)
      return result

    if (!geometry)
      return false

    return this.onMouseMoveCurrentGeometry(event, geometry, currentGeometries)
  }

  onMouseMovePreviewVertex (event, geometry, currentGeometries) {

    console.log("move Preview Vertex: "+this.vertexMode)
    if (!geometry && !currentGeometries.length) {
      //console.log("no geometry")
      this.vertexMode=true
      if(this.vertexMode==true){
        setPreviewVertex(this.getCurrentPosition(event,null,true, geometry), this.representationType)
      }

      return true
    } else if (!!currentGeometries.length && geometry && geometry.getAction() === geometry.actions.select) {
      const raycastHitObject = this.floorPlanerController.getRaycastHitObjectForGeometryByGeometryTypes(geometry, [geometryTypes.vertex, geometryTypes.edge])
      //console.log("yes geometry: "+raycastHitObject)

      if (raycastHitObject) {
        switch (raycastHitObject.geometryType) {
          case geometryTypes.vertex:
            //console.log("pvertex")
            return false
          case geometryTypes.edge:
            //console.log("pedge")
            if(this.vertexMode===true){
              //console.log("vertex")
              //console.log(geometry)
              setPreviewVertex(this.getCurrentPosition(event,null,false,geometry), this.representationType)
            }else{
              //console.log("edge")
              //let cEdge=raycastHitObject
              //let cEdge=this.getEdgeUnderPosition(this.getCurrentPosition(event))
              //console.log(cEdge)
              /*
              if(cEdge!==null){

                let vertices=cEdge.getVertices()
                console.log("draggedVertices: "+vertices.length)

                console.log(geometry.draggedVertex1+" :: "+geometry.draggedVertex2)
                this.updateEdgePosition(geometry,this.getCurrentPosition()

              }*/
            }

            return true
          default:
            disableMagicGuideLine()
            return false
        }
      }
    }

    setPreviewVertex() //ohne parameter
    disableMagicGuideLine()
    return undefined
  }

  onMouseMoveCurrentGeometry (event, geometry, currentGeometries) {
    //console.log("move Preview Vertex: "+this.vertexMode)
    //console.log("GEOMETRYACTION ")
    //console.log(geometry.getAction())
    //console.log(geometry)
    //console.log(event)


    //geometry.setAction(geometry.actions.dragVertex)

    switch (geometry.getAction()) {
      case geometry.actions.addVertex:
        if (currentGeometries.length !== 1)
          return false
        //console.log("addVertex")

        const lastVertex = geometry.getLastVertex()
        //console.log("lastVertex")

        geometry.updateVertex(this.getCurrentPosition(event, lastVertex), lastVertex)
        //console.log("lastVertex")
        return true
      case geometry.actions.dragVertex:

        if(this.vertexMode===true){
          //console.log("update Vertex")
          geometry.updateVertex(this.getCurrentPosition(event, geometry.draggedVertex))
        }else {
          //console.log("DRAG EDGE")
          //console.log(geometry.draggedEdge)

          this.updateEdgePosition(geometry,this.floorPlanerController.getCurrentPosition())

        }
        return true
      default:
        return false
    }
  }

  onLeftMouseDoubleClick (event, geometry, currentGeometries, minVerticesCount) {
    if (!geometry)
      return false

    //console.log("geometryactions doubleclick")
    switch (geometry.getAction()) {

      case geometry.actions.addVertex:
        return this.onClose(geometry, currentGeometries, minVerticesCount)
      default:
        return false
    }
  }

  onLeftMouseUp (event, geometry) {
    if (!geometry) {
      return false
    }

    switch (geometry.getAction()) {
      case geometry.actions.dragVertex:
        geometry.setAction(geometry.actions.select)
        this.changeWallEdgesToRail()
        geometry.draggedEdge=null
        geometry.draggedVertex=null
        this.floorPlanerController.saveChanges()
        this.resetVertices()
        disableMagicGuideLine()
        return true
      default:
        return false
    }
  }

  changeWallEdgesToRail(){

    let wallEdges=this.getRailWallEdges()
    //console.log("wallEdges "+wallEdges.length)
    //console.log(this.getWallEdges())
    for(let iEdge=0; iEdge<wallEdges.length; iEdge++) {
      //console.log("RAILL!")
      //console.log(wallEdges[iEdge])
      let railVertex=this.getRailVertex(wallEdges[iEdge])
      if(railVertex!==null){
        let railEdge=railVertex.railEdge
        let wallEdge=wallEdges[iEdge]
        //console.log("rail :::: wall")
        //console.log(railEdge)
        //console.log(wallEdge)
        let railVertices=railEdge.getVertices()
        let railPositions=[]
        railPositions.push(railVertices[0].position)
        railPositions.push(railVertices[1].position)
        let railDir=getSubtraction(railPositions[0],railPositions[1])
        //console.log("raildir")
        //console.log(railDir)

        let wallVertices=wallEdge.getVertices()
        let wallPositions=[]
        wallPositions.push(wallVertices[0].position)
        wallPositions.push(wallVertices[1].position)

        let wallDir=getSubtraction(wallPositions[0],wallPositions[1])
        //console.log("walldir")
        //console.log(wallDir)

        //console.log("wall  :::  rail")
        //console.log(wallPositions)
        //console.log(railPositions)

        let crossPoint=getCrossPoint(wallPositions[0],wallDir,railPositions[0],railDir)
        railVertex.setPosition(crossPoint)

      }

    }
  }


  onBackspaceDown (event, geometry, currentGeometries) {
    //console.log("PolyEdgeController backspace")
    //console.log(geometry)
    if (!geometry)
      return false

    switch (geometry.getAction()) {
      case geometry.actions.addVertex:
        if (geometry.getVerticesCount() <= 2) {
          //console.log("removeGeometryfromGroup")
          this.removeGeometryFromGroup(geometry, currentGeometries)
        } else {
          this.removeLastPolyEdge(geometry)
        }
        return true
      case geometry.actions.select:
        //console.log("REMOVE Poly Edge")
        this.removeGeometryFromGroup(geometry, currentGeometries)
        this.floorPlanerController.saveChanges()
        return true
      default:
        return false
    }
  }

  onEscapeDown (event, geometry, currentGeometries) {
    if (!geometry) {
      return false
    }

    switch (geometry.getAction()) {
      case geometry.actions.addVertex:
        this.removeGeometryFromGroup(geometry, currentGeometries)
        disableMagicGuideLine()
        return true
      case geometry.actions.select:
        geometry.setActive(false)
        this.removeGeometryFromCurrentGeometries(geometry, currentGeometries)
        return true
      default:
        return false
    }
  }

  onEnterDown (event, geometry, currentGeometries, minVerticesCount = 2) {
    //console.log("CLOSE PolyEdgeController")
    if (!geometry)
      return false

    switch (geometry.getAction()) {
      case geometry.actions.addVertex:
        return this.onClose(geometry, currentGeometries, minVerticesCount)
      default:
        return false
    }
  }

  onClose (geometry, currentGeometries, minVerticesCount) {
    const currentBorderGeometries=currentGeometries
    //console.log("try close")
    const vertices = geometry.getVertices()
    const edges = geometry.getEdges()


    if (!vertices[0].position.distanceToSquared(vertices[1].position)) {
      //console.log("remove")
      this.removeGeometryFromGroup(geometry, currentGeometries)
      return false
    }

    if (geometry.getVerticesCount() >= minVerticesCount) {//currentGeometries
      //console.log("before edge close: "+geometry.getVerticesCount()+" :: "+geometry.getEdges().length)


      //console.log(geometry.getVertices())
      //console.log(geometry.getEdges())

      //check if both vertices are inside the polygon

      let lastEdge=geometry.edgeGroup.getEdgeByIndex(-1)
      geometry.edgeGroup.removeEdge(lastEdge)//-EDGE

      geometry.close()
      //console.log("after edge close: "+geometry.getVerticesCount()+" :: "+geometry.getEdges().length)

      //console.log(geometry.getVertices())
      //console.log(geometry.getEdges())

      this.removeGeometryFromCurrentGeometries(geometry, currentGeometries)
      this.floorPlanerController.saveChanges()
      //console.log("after save")
      return true
    }
    return false
  }

  getCurrentPosition (event, ignoreVertex = null, isFirstVertex=false) {

    const otherPositions = this.floorPlanerController.getWallVertices()
      .filter(vertex => vertex !== ignoreVertex)
      .map(vertex => vertex.position)

    const otherEdgesPositions = this.floorPlanerController
      .getWallEdgePositions()

    return getSnappingPosition(event, this.floorPlanerController.getCurrentPosition(), otherPositions, otherEdgesPositions, isFirstVertex )
  }


  updateEdgePosition (geometry,currentPosition) {
    //console.log("updateEdgePosition ")
    //console.log(currentPosition)
    //console.log(geometry)

    if(geometry.draggedEdge){
      let vertices=geometry.draggedEdge.getVertices()

      let posA=new Vector2(vertices[0].position.x,vertices[0].position.y)
      let posB=new Vector2(vertices[1].position.x,vertices[1].position.y)
      //console.log(posA)
      //console.log(posB)

      let dirEdge=getSubtraction(posA,posB)
      //console.log("edge dir")
      //console.log(dirEdge)

      let direction=getClosestDirection(dirEdge.x,dirEdge.y)// MAGIC GUIDELINE
      //console.log("direction")
      //console.log(direction)

      let oDirection=getUnitVector(getPerp(direction))
      //console.log(oDirection)


      let pCross=getCrossPoint(posA,dirEdge,currentPosition,oDirection)  //vorher: direction
      //console.log(pCross)
      if(pCross !== null){
        let vCross=getSubtraction(currentPosition,pCross)

        //console.log("dragLength: "+vCross.length())
        //console.log(vCross)

        let posAOnEdge=getAddition(posA,vCross)
        let posBOnEdge=getAddition(posB,vCross)

        vertices[0].setPosition(posAOnEdge)
        geometry.updateVertex(posBOnEdge, vertices[1])
      }else{
        //console.log("NULL")
      }

    }else{
      //console.log("no dragged edge")
    }
  }
  removeLastPolyEdge(geometry){
    console.log("removeLastEdgePolyEdge")
    let lastEdge=geometry.edgeGroup.getEdgeByIndex(-1)
    geometry.edgeGroup.removeEdge(lastEdge)
    //lastEdge=geometry.edgeGroup.getEdgeByIndex(-1)//-EDGE
    //geometry.edgeGroup.removeEdge(lastEdge)

    /*console.log("removed")
    console.log( geometry.edgeGroup.children.length)

    console.log("removeLastVertex")*/
    //ab hier: alter code
    geometry.removeLastVertex()
    //console.log(geometry)
    //geometry.updateVertex(this.getCurrentPosition(event, geometry.getLastVertex()), geometry.getLastVertex())
    //console.log(geometry)

    //neu!!
    /*
    const startVertex = geometry.vertexGroup.getVertexByIndex(-1)
    const endVertex = geometry.vertexGroup.getVertexByIndex(0)
    geometry.edgeGroup.addEdge(startVertex, endVertex)*/

  }

  getRailWallEdges(){
    let railWallEdges=[]
    let wallEdges=this.getWallEdges()
    for(let iWall=0; iWall<wallEdges.length; iWall++){
      if(this.getRailVertex(wallEdges[iWall]) !== null){
        railWallEdges.push(wallEdges[iWall])
      }
    }
    return railWallEdges
  }

  getRailVertex(wallEdge){
    if(wallEdge.getVertices()[0].railEdge!=null) return wallEdge.getVertices()[0]
    if(wallEdge.getVertices()[1].railEdge!=null) return wallEdge.getVertices()[1]
    return null
  }

  getWallEdges(){
    let wallGeometries = this.floorPlanerController.getGeometriesByRepresentationType(representationTypes.wall)
    let wallEdges=[]
    for(let iWall=0; iWall<wallGeometries.length; iWall++){
      //console.log(wallGeometries[iWall].edgeGroup.children)
      //console.log(wallGeometries[iWall].children[0])//.vertexGroup[0])
      for(let iEdge=0; iEdge<wallGeometries[iWall].edgeGroup.children.length; iEdge++){
        wallEdges.push(wallGeometries[iWall].edgeGroup.children[iEdge])
      }
    }
    return wallEdges
  }
  getWallVerts(){
    let wallGeometries = this.floorPlanerController.getGeometriesByRepresentationType(representationTypes.wall)
    //console.log("wallllgeometries:")
    //console.log(wallGeometries)
    let wallVertices=[]
    for(let iWall=0; iWall<wallGeometries.length; iWall++){
      //console.log("walllll")
      //console.log(wallGeometries[iWall].vertexGroup.children)
      //console.log(wallGeometries[iWall].children[0])//.vertexGroup[0])
      for(let iVert=0; iVert<wallGeometries[iWall].vertexGroup.children.length; iVert++){
        wallVertices.push(wallGeometries[iWall].vertexGroup.children[iVert])
      }
    }

    // console.log("wallVertices"+wallVertices.length)
    //console.log(wallVertices)
    return wallVertices
  }
  resetVertices() {

    let wallVertices = this.getWallVerts()

    for(let i=0; i<wallVertices.length; i++){
      wallVertices[i].railEdge=null
    }
  }
}