import { Geometry } from './Geometry'
import { createFaceBufferGeometry, Face } from './FaceFactory'
import { getAreaWithHoles } from '../helper/GeometryHelper'
import { getFaceConfigByRepresentationType } from './FaceFactory'
import { Vector2 } from "three"
import { getMaterial } from '../common/Materials'
import { materialTypes } from '../common/MaterialTypes'
import { EdgeGroup } from './EdgeGroup'
import { Vertex } from './VertexFactory'
import { edgeToJSON } from '../helper/AxesHelper'

const spaceActions = {
  drag: 'drag',
}

export class SpaceGeometry extends Geometry {
  holes

  constructor (group, representationType, properties) {

    super(group, representationType, spaceActions)

    if (properties)
      this.properties = properties

    console.log("createSpaceGeometry " + properties.length + " ::")
    console.log("prop:")
    console.log(properties)

    this.setAction(this.actions.idle)

    if (this.properties) {
      const { vertices, holes } = this.properties
      this.setGeometry(vertices, holes)
      const { markerPos, spaceType } = this.properties
      if (!this.properties.spaceType) this.properties.spaceType = "zone"
      if (!this.properties.markerPos) this.properties.markerPos = null
      if (!this.properties.byHand) this.properties.byHand = false
      if (!this.properties.axes) this.properties.axes = []
      if (!this.properties.unknown) this.properties.unknown = true
      if (!this.properties.scale) this.properties.scale =1.0
      if (!this.properties.useSpaceType) this.properties.useSpaceType=0
      console.log("st:" + this.getSpaceType())
    }
    console.log("THIS-----")
    console.log(this)
    //this.face.config=this.getConfigWhite()
    //this.useSpaceType = 0

  }

  getConfigWhite () {
    return {
      activeMaterial: getMaterial(materialTypes.white),
      inactiveMaterial: getMaterial(materialTypes.white),
    }
  }

  getConfigWhiteTransparent () {
    return {
      activeMaterial: getMaterial(materialTypes.whiteTransparent),
      inactiveMaterial: getMaterial(materialTypes.whiteTransparent),
    }
  }

  logProperties() {
    console.log("properties:")
    console.log(this.toJSON())
  }

  getConfigZone () {
    return {
      activeMaterial: getMaterial(materialTypes.brightOrange),
      inactiveMaterial: getMaterial(materialTypes.brightOrange),
    }
  }

  getMarkerPos () {
    return this.properties.markerPos
  }

  setMarkerPos (pos) {
    this.properties.markerPos = pos;
  }

  isUnkown()
  {
    return this.properties.unknown
  }

  setUnknown(unknown){
    this.properties.unknown=unknown
  }

  getSpaceType () {
    return this.properties.spaceType
  }

  setByHand(byHand){
    this.properties.byHand=byHand
    this.properties.unknown=false
  }
  getByHand(){
    return this.properties.byHand
  }

  setSpaceType (position, type) {
    console.log("mark: " + type + "!!")
    this.properties.markerPos = new Vector2(position.x, position.y)
    this.properties.spaceType = type
    if (type === 'zone') this.properties.unknown=true
    else this.properties.unkown=false
    this.toJSON()
  }

  getAxes(){
    return this.properties.axes
  }
  setAxes(axes){
    this.properties.axes=axes
    this.properties.unknown=false
  }

  setUseSpaceType(use){
    this.properties.useSpaceType=use;
  }

  getUseSpaceType(){
    return this.properties.useSpaceType
  }

  updateSpaceTextures(){
    console.log("iiindex: "+this.getUseSpaceType()+" "+this.properties.spaceType)
    if(this.face!==null){
      if(this.properties.useSpaceType===1){
        console.log("do use: "+this.getSpaceType())
        this.face.config=getFaceConfigByRepresentationType(this.representationType,this.getSpaceType())
      }else if((this.properties.useSpaceType===0)){//||(this.properties.useSpaceType===3)){
        console.log("dont use")
        this.face.config=getFaceConfigByRepresentationType(this.representationType)
      }else if(this.properties.useSpaceType===2){
        console.log("white")
        if(this.getSpaceType()==="zone"){
          this.face.config=this.getConfigZone()
          //this.face.config=this.getConfigZone()
          //this.face.config=getFaceConfigByRepresentationType(this.representationType,"white")
        }
        else{
          console.log("no zone")
          this.face.config=this.getConfigWhite()
        }
      }else if(this.properties.useSpaceType===3){
        console.log("white transparent "+this.properties.useSpaceType)
        this.face.config=this.getConfigWhiteTransparent()
      }
      this.face.setActive(true)
    }
  }


  setGeometry (vertices, holes = []) {
    if (!vertices && this.vertexGroup.getCount() === 0) {
      return
    }

    if (this.vertexGroup.getCount() === 0) {
      vertices.forEach(vertex => this.vertexGroup.addVertex(vertex))
    }

    const positions = this.vertexGroup.getPositions()

    if (!this.face) {
      this.face = new Face(this.representationType, positions, holes)
      this.add(this.face)
    } else {
      this.face.dispose()
      this.face.geometry = createFaceBufferGeometry(positions, holes)
    }

    this.area = getAreaWithHoles(positions, holes)
    //console.log(this.area)
    this.holes = holes
    console.log("setGeometry type")
    console.log(this.properties)

    this.updateSpaceTextures()
  }

  setActive (active) {
    super.setActive(active)
    if (this.face) {
      this.face.setActive(active)
    }
  }

  moveDelta (movement) {
    this.position.setX(this.position.x + movement.x)
    this.position.setY(this.position.y + movement.y)

    this.vertexGroup.moveDeltaAllVertices(movement)
    this.properties.holes.forEach(hole => hole.forEach(vertex => {
      vertex.x += movement.x
      vertex.y += movement.y
    }))
  }

  isSingleDraggable () {
    return false
  }

  toJSON () {
    const json = super.toJSON()

    console.log("to JSON: "+this.properties.useSpaceType)
    console.log(json)

    json.properties = {
      previewAxis: this.properties.previewAxis,
      axes: this.axes,
      holes: this.holes,
      area: this.area,
      scale: this.properties.scale,
      markerPos: this.properties.markerPos,
      spaceType: this.properties.spaceType,
      byHand: this.properties.byHand,
      unknown: this.properties.unknown,
      useSpaceType: this.properties.useSpaceType
    }
    //console.log("ST:"+this.ge:tSpaceType()+"MP: "+this.getMarkerPos())
    console.log("to json")
    //console.log(json)

    return json
  }

  fromJSON (json) {
    console.log("from json")
    console.log(json)
    super.fromJSON(json)
    const { holes } = json.properties
    this.setGeometry(null, holes)

  }

  clearAxes(){
    this.properties.axes=[]
    console.log("clear Axes"+this.properties.axes.length)
    this.setUnknown(false)
    this.setByHand(true)
  }

  setSelectedAxis(axisJSON){
    this.setUnknown(false)
    this.properties.selectedAxis=axisJSON
  }

  addAxis(axisJSON){
    this.setUnknown(false)
    if(this.properties.axes===null){
      this.properties.axes=[]
    }
    console.log(this.properties.axes)
    let n=this.properties.axes.length

    console.log(axisJSON)
    console.log("Before addition: "+this.properties.axes.length)
    this.properties.axes.push(axisJSON)
    console.log("After addition: "+this.properties.axes.length)
  }
}