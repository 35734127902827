import { GraphVertex } from './GraphVertex'
import { GraphEdge } from './GraphEdge'
import {
  findNearestVertices,
  getEdgeIntersection,
  isEdgesIntersection,
  isLinesOverlapping,
  isPointBetween,
  isVerticesContainsAllVertices,
  isVerticesContainsVertex,
} from '../helper/GeometryHelper'
import { GraphFace } from './GraphFace'

export const graphRepresentationTypes = {
  outerOutline: 'outerOutline',
  intersectionOuterOutline: 'intersectionOuterOutline',

  innerOutline: 'innerOutline',
  intersectionInnerOutline: 'intersectionInnerOutline',

  wall: 'wall',
  intersectionWall: 'intersectionWall',

  wallConnection: 'wallConnection',

  intersection: 'intersection',
}

export class Graph {
  outline = []
  holes = []

  vertices = []
  edges = []
  faces = []

  constructor (geometries, outline, holes) {
    this.outline = outline
    this.holes = holes

    console.log("outline:"+outline)
    console.log("holes:"+holes)

    // Create vertices and edges for each geometry
    geometries.forEach(geometry => this.createVerticesAndEdges(geometry))
    console.log("vertices:"+this.vertices.length+"  edges:"+this.edges.length)

    //this.concatVerticesAnotherEdge()
    //this.removeWrongEdgesAfterConcat()

    this.mergeVerticesByDistance()
    this.removeWrongEdgesIntersections()

    this.createOverlappingVerticesAndEdges()
    this.removeWrongEdgesIntersections()


    // Create intersection vertices and edges
    this.createIntersectionVerticesAndEdges()
    this.removeVerticesOutside()
    this.removeWrongEdgesIntersections()

    this.removeWrongEdgesAfterConcat()

    this.reconnectSingleEdgeVertices()

    this.removeSingleEdgeVertices()


    this.createFaces()
    console.log("nFaces:"+this.faces.length)
    //console.log(this.vertices)
    //console.log(this.edges)
  }

  ///region Initial

  createVerticesAndEdges (geometry) {
    const { positions, geometryUuid, representationType } = geometry

    const vertices = positions.map(position => new GraphVertex(position, representationType, geometryUuid))
    this.vertices = this.vertices.concat(vertices)

    let verticesCount = vertices.length
    for (let i = 0; i < verticesCount; i++) {
      this.edges.push(new GraphEdge(vertices[i], vertices[(i + 1) % verticesCount], representationType, geometryUuid))
    }
  }

  ///endregion

  ///region

  /**
   * Concat vertices with edges if the vertex is exact on an edge
   */
  concatVerticesAnotherEdge () {
    const edgeMap = new Map()

    this.vertices.forEach(vertex => {
      for (let i = 0; i < this.edges.length; i++) {
        const edge = this.edges[i]
        if (edge.geometryUuid === vertex.geometryUuid) {
          continue
        }
        const vertices = edge.getVertices()

        if (!isPointBetween(vertices[0].position, vertices[1].position, vertex.position)) {
          continue
        }

        if (edgeMap.has(edge)) {
          edgeMap.get(edge)
            .push(vertex)
        } else {
          edgeMap.set(edge, [vertex])
        }
      }
    })

    const edges = [...edgeMap.keys()]

    let overlappingEdgesList = []

    // Find overlapping edges
    for (let i = 0; i < edges.length; i++) {
      let overlapping = false
      const edgeToTest = edges[i]
      for (let k = i + 1; k < edges.length; k++) {
        if (i === k) {
          continue
        }

        const otherEdge = edges[k]

        if (isLinesOverlapping(edgeToTest, otherEdge)) {
          let listToAdd = overlappingEdgesList.find(overlappingEdges => overlappingEdges.includes(edgeToTest))
          if (listToAdd) {
            listToAdd.push(otherEdge)
          }
          listToAdd = overlappingEdgesList.find(overlappingEdges => overlappingEdges.includes(otherEdge))
          if (listToAdd) {
            listToAdd.push(edgeToTest)
          } else {
            overlappingEdgesList.push([edgeToTest, otherEdge])
          }

          overlapping = true
        }
      }

      const listToAdd = overlappingEdgesList.find(overlappingEdges => overlappingEdges.includes(edgeToTest))
      if (!overlapping && !listToAdd) {
        overlappingEdgesList.push([edgeToTest])
      }
    }

    overlappingEdgesList.forEach(overlappingEdges => {
      const verticesList = overlappingEdges.map(edge => edgeMap.get(edge))
        .flat(2)

      let { outerVertices, innerVertices } = this.getStartAndEndVertexFromOverlappingLines(overlappingEdges)

      innerVertices = innerVertices.concat(verticesList.filter(vertex => !innerVertices.includes(vertex)))

      let allVertices = outerVertices.concat(innerVertices)

      innerVertices.forEach(vertex => {
        const neighborsWithEdge = vertex.getNeighborsWithEdge()
        neighborsWithEdge.filter(neighborWithEdge => allVertices.includes(neighborWithEdge.neighbor))
          .map(neighborsWithEdge => neighborsWithEdge.edge)
          .forEach(edge => this.removeEdge(edge))
      })

      const edgeReferenceType = overlappingEdges.find(edge => edge.isRepresentationType(graphRepresentationTypes.outerOutline)) !== null ?
        graphRepresentationTypes.outerOutline : overlappingEdges.find(edge => edge.isRepresentationType(graphRepresentationTypes.innerOutline)) ?
          graphRepresentationTypes.innerOutline : graphRepresentationTypes.wall

      overlappingEdges.forEach(edge => this.removeEdge(edge))
      innerVertices.push(outerVertices[1])
      this.insertEdgesBetweenVertices(outerVertices[0], allVertices, edgeReferenceType)
    })
  }

  removeWrongEdgesAfterConcat() {

    //const concatEdges = this.edges.filter(edge => edge.geometryUuid === undefined && edge.getDistance() < .5)
    //ToDo: vielleicht entfernung ganz weglassen
    const concatEdges = this.edges.filter(edge => edge.getDistance() < .5) //vorher: .25

    concatEdges.forEach(edge => {
      const verticesOfEdges = edge.getVertices()

      if (verticesOfEdges[0].getEdges().length > 2 && verticesOfEdges[1].getEdges().length > 2) {
        this.removeEdge(edge)
      }
    })
  }



  ///

  ///region Merge vertices

  mergeVerticesByDistance () {
    //TODO: check

    const tolerance = 0.05 //0.2
    //const tolerance = 0.05 //0.2
    const squareTolerance = tolerance * tolerance

    const wallVertices = this.vertices.filter(vertex => vertex.isRepresentationType(graphRepresentationTypes.wall))
    const verticesToRemove = []

    for (let i = wallVertices.length - 1; i >= 0; i--) {
      const testVertex = wallVertices[i]
      for (let k = i - 1; k >= 0 && testVertex; k--) {
        const mergeVertex = wallVertices[k]

        if (testVertex.geometryUuid === mergeVertex.geometryUuid) {
          continue
        }

        const distance = testVertex.position.distanceToSquared(mergeVertex.position)

        if (distance > squareTolerance) {
          continue
        }
       // console.log("distance:"+distance+" tolerance:"+squareTolerance)

        testVertex.setRepresentationType(graphRepresentationTypes.intersectionWall)
        const edges = mergeVertex.getEdges()
        edges.map(edge => edge.getOtherVertex(mergeVertex))
          .forEach(vertex => this.edges.push(new GraphEdge(testVertex, vertex, graphRepresentationTypes.wall)))
        verticesToRemove.push(mergeVertex)
      }
    }

    verticesToRemove.forEach(vertex => this.removeVertex(vertex))
  }

///endregion

  ///region Edges intersections

  createIntersectionVerticesAndEdges () {
    const intersectionPerEdge = {}

    // Create vertices per intersection
    for (let i = 0; i < this.edges.length; i++) {
      const edgeA = this.edges[i]
      for (let k = i + 1; k < this.edges.length; k++) {
        const edgeB = this.edges[k]

        /*
        if (edgeA.geometryUuid === edgeB.geometryUuid) {
          continue
        }
        */

        if (isEdgesIntersection(edgeA, edgeB)) {
          const intersectionVertex = this.createVertexByIntersection(edgeA, edgeB)
          if (!intersectionVertex) {
            continue
          }

          if (intersectionPerEdge[edgeA.uuid]) {
            intersectionPerEdge[edgeA.uuid].push(intersectionVertex)
          } else {
            intersectionPerEdge[edgeA.uuid] = [intersectionVertex]
          }

          if (intersectionPerEdge[edgeB.uuid]) {
            intersectionPerEdge[edgeB.uuid].push(intersectionVertex)
          } else {
            intersectionPerEdge[edgeB.uuid] = [intersectionVertex]
          }
        }
      }
    }

    for (const edgeUuid in intersectionPerEdge) {
      const edge = this.edges.find(edge => edge.uuid === edgeUuid)
      this.edges = this.edges.filter(e => e !== edge)
      const createdVertices = intersectionPerEdge[edgeUuid]
      this.insertVerticesToEdge(edge, createdVertices)
    }
  }

  createOverlappingVerticesAndEdges () {
    const wallEdges = this.edges.filter(edge => edge.isRepresentationType(graphRepresentationTypes.wall))
    const overlappingEdgesList = []

    for (let i = wallEdges.length - 1; i >= 0; i--) {
      const edgeToTest = wallEdges[i]
      for (let k = i - 1; k >= 0; k--) {
        const wallEdge = wallEdges[k]
        if (isLinesOverlapping(edgeToTest, wallEdge)) {
          console.log('OVERLAPPING')
          const overlappingEdges = overlappingEdgesList.find(overlappingEdges => overlappingEdges.includes(edgeToTest) ||
            overlappingEdges.includes(wallEdge))

          if (overlappingEdges) {
            if (overlappingEdges.includes(edgeToTest)) {
              overlappingEdges.push(wallEdge)
            }
            if (overlappingEdges.includes(wallEdge)) {
              overlappingEdges.push(edgeToTest)
            }
          } else {
            overlappingEdgesList.push([wallEdge, edgeToTest])
          }
        }
      }
    }

    overlappingEdgesList.forEach(overlappingEdges => {
      const { outerVertices, innerVertices } = this.getStartAndEndVertexFromOverlappingLines(overlappingEdges)

      innerVertices.forEach(vertex => vertex.setRepresentationType(graphRepresentationTypes.intersectionWall))

      overlappingEdges.forEach(edge => this.removeEdge(edge))

      this.insertEdgesBetweenVertices(outerVertices[0], innerVertices.concat(outerVertices[1]), graphRepresentationTypes.wall)
    })
  }

  insertEdgesBetweenVertices (startVertex, otherVertices, representationType) {
    let currentVertex = startVertex

    while (otherVertices.length > 1) {
      const nearestVertex = findNearestVertices(currentVertex, otherVertices)[0]
      otherVertices = otherVertices.filter(vertex => vertex !== currentVertex)
      this.edges.push(new GraphEdge(currentVertex, nearestVertex, representationType))
      currentVertex = nearestVertex
    }
  }

  getStartAndEndVertexFromOverlappingLines (edges) {
    const vertices = edges.map(edge => edge.getVertices())
      .flat(2)

    let maxDistance = 0
    let maxVertices = []

    for (let i = 0; i < vertices.length; i++) {
      const startVertex = vertices[i]
      for (let k = i + 1; k < vertices.length; k++) {
        const endVertex = vertices[k]
        const distance = startVertex.position.distanceToSquared(endVertex.position)
        if (maxDistance < distance) {
          maxDistance = distance
          maxVertices = [startVertex, endVertex]
        }
      }
    }

    return {
      outerVertices: maxVertices,
      innerVertices: vertices.filter(vertex => !maxVertices.includes(vertex)),
    }
  }

  createVertexByIntersection (edgeA, edgeB) {
    const intersection = getEdgeIntersection(edgeA, edgeB)

    if (!intersection) {
      return null
    }

    const isEdgeAOuterOutline = edgeA.isRepresentationType(graphRepresentationTypes.outerOutline)
    const isEdgeBOuterOutline = edgeB.isRepresentationType(graphRepresentationTypes.outerOutline)
    const isEdgeAInnerOutline = edgeA.isRepresentationType(graphRepresentationTypes.innerOutline)
    const isEdgeBInnerOutline = edgeB.isRepresentationType(graphRepresentationTypes.innerOutline)

    const isPartOfOuterOutline = (isEdgeAOuterOutline || isEdgeBOuterOutline)
    const isPartOfInnerOutline = (isEdgeAInnerOutline || isEdgeBInnerOutline)

    const representationType = isPartOfOuterOutline ? graphRepresentationTypes.intersectionOuterOutline :
      isPartOfInnerOutline ? graphRepresentationTypes.intersectionInnerOutline : graphRepresentationTypes.intersectionWall

    const createdVertex = new GraphVertex(intersection, representationType)
    this.vertices.push(createdVertex)
    return createdVertex
  }

  insertVerticesToEdge (edge, createdVertices) {
    const edgeVertices = edge.getVertices()

    if (edgeVertices.length !== 2) {
      return
    }

    edgeVertices.forEach(vertex => vertex.removeEdge(edge))
    let currentVertex = edgeVertices[0]
    createdVertices.push(edgeVertices[1])

    do {
      const nearestVertex = findNearestVertices(currentVertex, createdVertices)[0]
      createdVertices = createdVertices.filter(vertex => vertex !== currentVertex)
      this.edges.push(new GraphEdge(currentVertex, nearestVertex, edge.getRepresentationType(), edge.getGeometryUuid()))

      currentVertex = nearestVertex
    } while (currentVertex && currentVertex !== edgeVertices[1])
  }

  ///endregion

  ///region Remove vertices outside outline or inside holes

  removeVerticesOutside () {
    const representationTypes = [
      graphRepresentationTypes.outerOutline,
      graphRepresentationTypes.innerOutline,
      graphRepresentationTypes.intersectionInnerOutline,
      graphRepresentationTypes.intersectionOuterOutline]
    const verticesWithoutOutline = this.vertices.filter(vertex => !representationTypes.includes(vertex.getRepresentationType()))
    let verticesPositions = verticesWithoutOutline.map(vertex => vertex.position)

    for (let i = verticesPositions.length - 1; i >= 0; i--) {
      const vertexPosition = verticesPositions[i]
      if (!isVerticesContainsVertex(this.outline, vertexPosition)) {
        const vertex = verticesWithoutOutline[i]
        this.removeVertex(vertex)
      }
      this.holes.forEach(hole => {
        if (isVerticesContainsVertex(hole, vertexPosition)) {
          const vertex = verticesWithoutOutline[i]
          this.removeVertex(vertex)
        }
      })
    }
  }

  removeVertex (vertex) {
    const removedEdges = vertex.getEdges()
    this.vertices = this.vertices.filter(v => v !== vertex)
    this.edges = this.edges.filter(edge => !removedEdges.includes(edge))
    this.vertices.forEach(v => removedEdges.forEach(edge => v.removeEdge(edge)))
  }

  ///endregion

  ///region Reconnect vertices

  reconnectSingleEdgeVertices () {
    const singleEdgeVertices = this.vertices.filter(vertex => vertex.getEdges().length === 1)
    //console.log("reconnect sev:"+singleEdgeVertices.length)
    for (let i = 0; i < singleEdgeVertices.length; i++) {
      const vertexToTest = singleEdgeVertices[i]
      const vertexToTestNeighbors = vertexToTest.getNeighbors()
      for (let k = i + 1; k < singleEdgeVertices.length; k++) {
        const otherVertex = singleEdgeVertices[k]
        if (vertexToTest.position.distanceTo(otherVertex.position) < .2 &&
          !vertexToTestNeighbors.includes(otherVertex)) {
          this.edges.push(new GraphEdge(vertexToTest, otherVertex, graphRepresentationTypes.wallConnection))
        }
      }
    }
  }

  ///endregion

  ///region Remove wrong connection

  removeWrongEdgesIntersections () {
    const edgesToTest = this.edges.filter(edge => edge.getDistance() < .25)

    for (let i = edgesToTest.length - 1; i >= 0; i--) {
      const edge = edgesToTest[i]
      const vertices = edge.getVertices()

      if (edge.isRepresentationType(graphRepresentationTypes.outerOutline) &&
        vertices[0].isRepresentationType(graphRepresentationTypes.intersectionOuterOutline) &&
        vertices[1].isRepresentationType(graphRepresentationTypes.intersectionOuterOutline)) {
        this.removeEdge(edge)
      } else if (edge.isRepresentationType(graphRepresentationTypes.innerOutline) &&
        vertices[0].isRepresentationType(graphRepresentationTypes.intersectionInnerOutline) &&
        vertices[1].isRepresentationType(graphRepresentationTypes.intersectionInnerOutline)) {
        this.removeEdge(edge)
      } else if (edge.isRepresentationType(graphRepresentationTypes.wall) &&
        ((vertices[0].isRepresentationType(graphRepresentationTypes.intersectionWall) &&
            vertices[1].isRepresentationType(graphRepresentationTypes.wall)) ||
          (vertices[0].isRepresentationType(graphRepresentationTypes.wall) &&
            vertices[1].isRepresentationType(graphRepresentationTypes.intersectionWall)) ||
          (vertices[0].isRepresentationType(graphRepresentationTypes.intersectionWall) &&
            vertices[1].isRepresentationType(graphRepresentationTypes.intersectionWall)))) {
        this.removeEdge(edge)
      }
    }
  }

  removeEdge (edge) {
    const vertices = edge.getVertices()
    this.edges = this.edges.filter(e => e !== edge)
    vertices[0].removeEdge(edge)
    vertices[1].removeEdge(edge)
  }

  ///endregion

  ///region Remove single edge vertices

  removeSingleEdgeVertices () {
    let singleEdgeVertices = this.vertices.filter(vertex => vertex.getEdges().length <= 1)
    //console.log("singleEdgeVertices:"+singleEdgeVertices.length)
    while (singleEdgeVertices.length !== 0) {
      singleEdgeVertices.forEach(vertex => this.removeVertex(vertex))
      singleEdgeVertices = this.vertices.filter(vertex => vertex.getEdges().length <= 1)
    }

  }

  ///endregion

  ///region Create faces

  createFaces () {
    this.faces = []
    let startVertices = this.vertices

    let usedVertices = []
    /*
    for(let vIndex=0; vIndex<startVertices.length; vIndex++){
      let cEdges=startVertices[vIndex].getEdges()
      let nEdges=cEdges.length

      let pos=startVertices[vIndex].position

      console.log("vertex: pos:"+pos.x+":"+pos.y+" nEdges:"+nEdges)
      if(nEdges>=3){
        for(let eIndex=0; eIndex<nEdges; eIndex++){
          let posA=cEdges[eIndex].vertices[0].position
          let posB=cEdges[eIndex].vertices[1].position
          let distance=cEdges[eIndex].distance
          console.log("edge"+eIndex+" a:"+posA.x+","+posA.y+" b:"+posB.x+","+posB.y+" d:"+distance)
        }

      }
    }*/
    while (startVertices.length > 0) {
     // console.log("startvertices length:"+startVertices.length)
      let currentVertex = startVertices[0]
      let previousEdge = currentVertex.getEdges()[0]

      const verticesPerSpace = [currentVertex]
      currentVertex = previousEdge.getOtherVertex(currentVertex)

      while (currentVertex && !verticesPerSpace.includes(currentVertex)) {
        verticesPerSpace.push(currentVertex)

        if (currentVertex.getEdges().length === 2) {
          previousEdge = currentVertex.getEdges()
            .find(edge => edge !== previousEdge)
          currentVertex = previousEdge.getOtherVertex(currentVertex)
        }
        // Unused for now
        else if (currentVertex.getEdges().length > 2) {
          previousEdge = currentVertex.getNextEdge(previousEdge)
          currentVertex = previousEdge.getOtherVertex(currentVertex)
        }
      }

      usedVertices = usedVertices.concat(verticesPerSpace)
      startVertices = startVertices.filter(vertex => !verticesPerSpace.includes(vertex))

      const innerOutlineVertices = verticesPerSpace.filter(vertex => vertex.isRepresentationType(graphRepresentationTypes.innerOutline))
      if (verticesPerSpace.length !== innerOutlineVertices.length) {
        const faceOutline = verticesPerSpace.map(vertex => vertex.position)
        const faceHoles = this.getFaceHoles(faceOutline)
        //console.log("outline:"+faceOutline.length+"  holes:"+faceHoles.length)

        this.faces.push(new GraphFace(faceOutline, faceHoles))

      }
    }

    this.addHolesToFaceForWalls()
  }

  addHolesToFaceForWalls () {
    for (let i = 0; i < this.faces.length; i++) {
      const faceToTest = this.faces[i]

      for (let k = 0; k < this.faces.length; k++) {
        if (k === i) {
          continue
        }

        const otherFace = this.faces[k]

        console.log("facaToFace")
        if (isVerticesContainsAllVertices(faceToTest.getOutline(), otherFace.getOutline())) {
          if (otherFace.getParentFace() &&
            isVerticesContainsAllVertices(faceToTest.getOutline(), otherFace.getParentFace()
              .getOutline())) {

          } else {
            otherFace.setParentFace(faceToTest)
          }
        }
      }
    }

    for (let i = this.faces.length - 1; i >= 0; i--) {
      const faceToTest = this.faces[i]
      let parentFace = faceToTest.getParentFace()
      let parents = 0
      while (parentFace !== null) {
        parentFace = parentFace.getParentFace()
        parents++
      }

      if (parents % 2 === 1) {
        faceToTest.getParentFace()
          .addHole(faceToTest.getOutline())
        this.faces = this.faces.filter(face => face !== faceToTest)
      }
    }
  }

  getFaceHoles (outline) {
    return this.holes.filter(hole => isVerticesContainsAllVertices(outline, hole))
  }

  getFaces () {
    return this.faces
  }

  ///endregion
}