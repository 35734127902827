import {createFaceBufferGeometry} from '../geometries/FaceFactory'
import {BufferAttribute, MathUtils, Matrix4, Vector2} from 'three'
import {isPolygonClockwise} from './GeometryHelper'
import {representationTypes} from '../config/RepresentationTypes'

export function createFaceBufferGeometryRingOffset(vertices, offsetOutward = .2, offsetInward = .2) {

    let inwardVertices = getOffsetByRingVertices(vertices, offsetInward)
    let outwardVertices = getOffsetByRingVertices(vertices, -offsetOutward)

    if (isPolygonClockwise(vertices)) {
        const tmp = inwardVertices
        inwardVertices = outwardVertices
        outwardVertices = tmp
    }

    const geometry = createFaceBufferGeometry(outwardVertices, [inwardVertices])

    return {geometry: geometry, innerVertices: inwardVertices, outerVertices: outwardVertices}
}

export function createFaceBufferGeometryLinearOffset(vertices, offsetLeft = .2, offsetRight = .2) {

    const faceVertices = getOffsetByLinearVertices(vertices, offsetLeft)
        .concat(getOffsetByLinearVertices(vertices, -offsetRight).reverse())
    const geometry = createFaceBufferGeometry(faceVertices)

    return {geometry: geometry, vertices: faceVertices}
}

function getOffsetByLinearVertices(vertices, offset = .5) {
    //console.log("Offsetting")
    let result = []

    if(vertices.length>=2) {
      const startVertex = new Vector2(vertices[0].x, vertices[0].y)
      const endVertex = new Vector2(vertices[1].x, vertices[1].y)

      let point = startVertex.clone()
        .sub(endVertex)
        .normalize()
        .multiplyScalar(offset)
        .add(startVertex)
        .rotateAround(startVertex, MathUtils.degToRad(-90))
      result.push(point)

      const offsetBuffer = new BufferAttribute(new Float32Array([offset, 0, 0]), 3)

      for (let i = 1; i < vertices.length - 1; i++) {
        result.push(getOffsetPositionBetweenVertices(vertices[i - 1], vertices[i], vertices[i + 1], offsetBuffer))
      }

      const startVertex2 = new Vector2(vertices[vertices.length - 1].x, vertices[vertices.length - 1].y)
      const endVertex2 = new Vector2(vertices[vertices.length - 2].x, vertices[vertices.length - 2].y)

      let point2 = startVertex2.clone()
        .sub(endVertex2)
        .normalize()
        .multiplyScalar(offset)
        .add(startVertex2)
        .rotateAround(startVertex2, MathUtils.degToRad(90))
      result.push(point2)
    }
    return result
}

function getOffsetByRingVertices(vertices, offset) {
    let result = []

    const offsetBuffer = new BufferAttribute(new Float32Array([offset, 0, 0]), 3)

    for (let i = 0; i < vertices.length; i++) {
        result.push(getOffsetPositionBetweenVertices(vertices[i - 1 < 0 ? vertices.length - 1 : i - 1], vertices[i], vertices[i + 1 === vertices.length ? 0 : i + 1], offsetBuffer))
    }

    return result
}

function getOffsetPositionBetweenVertices(previousVertex, currentVertex, nextVertex, offset) {
    let v1 = new Vector2().subVectors(previousVertex, currentVertex);
    let v2 = new Vector2().subVectors(nextVertex, currentVertex);
    let angle = v2.angle() - v1.angle()
    let hA = angle * 0.5
    let tA = v2.angle() + Math.PI * 0.5

    let shift = Math.tan(hA - Math.PI * 0.5)
    let shiftMatrix = new Matrix4().set(
        1, 0, 0, 0,
        -shift, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
    )

    let rotationMatrix = new Matrix4().set(
        Math.cos(tA), -Math.sin(tA), 0, 0,
        Math.sin(tA), Math.cos(tA), 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1
    )

    let translationMatrix = new Matrix4().set(
        1, 0, 0, currentVertex.x,
        0, 1, 0, currentVertex.y,
        0, 0, 1, 0,
        0, 0, 0, 1,
    )

    let cloneOffset = offset.clone();

    cloneOffset.applyMatrix4(shiftMatrix)
    cloneOffset.applyMatrix4(rotationMatrix)
    cloneOffset.applyMatrix4(translationMatrix)

    return new Vector2(cloneOffset.getX(0), cloneOffset.getY(0))
}

export function getIncreaseOffsetByRepresentationType(currentValue, representationType) {
    let offsetting = getOffsettingByRepresentationType(representationType)
    let newValue = currentValue

    for (let i = 0; i < offsetting.length - 1; i++) {
        if (offsetting[i] > currentValue) {
            newValue = offsetting[i]
            i = offsetting.length
        }
    }

    return newValue
}

export function getDecreaseOffsetByRepresentationType(currentValue, representationType) {
    let offsetting = getOffsettingByRepresentationType(representationType)
    let newValue = currentValue

    for (let i = offsetting.length - 1; i >= 0; i--) {
        if (offsetting[i] < currentValue) {
            newValue = offsetting[i]
            i = -1
        }
    }

    return newValue
}

function getOffsettingByRepresentationType(representationType) {
    let offsetting = []

    switch (representationType) {
        case representationTypes.wall:
            offsetting = offsettingWall
            break
        case representationTypes.outline:
            offsetting = offsettingOutline
            break
        default:
            break
    }

    return offsetting
}

const offsettingWall = [.1, .2, .4, .6, .8, 1]
const offsettingOutline = [.1, .2, .4, .6, .8, 1]